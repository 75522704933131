import React from "react"
import { graphql } from "gatsby"

import Layout, { Section } from "../components/Layout"
import { Members } from "../components/Members"
import { Link } from "../components/Link"
import Content, { HTMLContent } from "../components/Content"
import PageImage from "../components/PageUnsplashImage"

const AboutPageTemplate = ({ title, content, image, imageCredit, members, contentComponent }) => {
  const PageContent = contentComponent || Content

  return (
    <div>
      <PageImage src={image} credit={imageCredit}>
        <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
          {title}
        </h1>
      </PageImage>

      <Section>
        <PageContent className="content" content={content} />
        <h2 className="title is-size-3 has-text-weight-bold is-bold-light">Cooperação</h2>
        <p>
          Acreditamos fortemente na cooperação e que nossos objetivos só serão alcançados com a
          participação de muitos. Abaixo, aqueles e aquelas que, da sua forma, tem colaborado com a
          formação deste Instituto e a todos e todas, nosso muito obrigado!
        </p>
        <p>
          Deseja fazer parte desse trabalho?{" "}
          <Link to="/contact?formVariation=about">Entra em contato com a gente.</Link>
        </p>
        <Members members={members} />
      </Section>
    </div>
  )
}

const AboutPage = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={post.frontmatter.image}
        imageCredit={post.frontmatter.imageCredit}
        members={post.frontmatter.members}
      />
    </Layout>
  )
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        imageCredit {
          author
          href
        }
        image
        members {
          name
          description
          mpriority
          role
          linkedin
          instagram
          lattes
          twitter
          picture
        }
      }
    }
  }
`
