import React from "react"

import { Social } from "./Icons"

const SocialPreset = {
  linkedin: {
    icon: Social.LinkedIn,
  },
  instagram: {
    icon: Social.Instagram,
  },
  twitter: {
    icon: Social.Twitter,
  },
  lattes: {
    icon: Social.Lattes,
  },
}

const Members = ({ members }) => {
  return (
    <div>
      {members &&
        members
          .sort((a, b) => {
            let order = Number(a.mpriority) - Number(b.mpriority)
            return order || a.name.localeCompare(b.name)
          })
          .map((member) => <Member key={member.name} member={member} />)}
    </div>
  )
}

const Member = ({ member }) => {
  const socialLinks = ["linkedin", "twitter", "instagram", "lattes"].filter(
    (social) => member[social]
  )
  return (
    <div className="card" style={{ boxShadow: "none" }} id={member.name.replace(/ /g, "-")}>
      <div className="card-content">
        <div className="media">
          <div className="media-left">
            <Avatar src={member.picture} />
          </div>
          <div className="media-content">
            <p className="title is-4 mt-2">{member.name}</p>
            {member.role && <p className="subtitle is-6 mb-2">{member.role}</p>}
            {member.description &&
              member.description.map((d) => <p className="subtitle is-6 mb-1">{d}</p>)}
            {socialLinks.length > 0 && (
              <div className="member-social">
                {socialLinks.map((social) => {
                  const link = member[social]
                  const preset = SocialPreset[social]
                  const Icon = preset.icon
                  return (
                    <a
                      title={social}
                      key={social}
                      href={link}
                      className="is-size-4 mr-2 dark"
                      target="_blank"
                      rel="noreferrer">
                      <Icon />
                    </a>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const Avatar = ({ src }) => (
  <figure className="image is-128x128" width="128" height="128">
    <img alt="avatar-figure" className="is-rounded" src={src} />
  </figure>
)

export { Members, Member }
